<template>
  <div class="body" ref="DomBody" v-loading="saveLoading">
    <!-- 配置 -->
    <div class="setting">
      <!-- 表头 -->
      <div class="titleArea btnGroup">
        <!-- 模板选择 -->
        <div>
          <el-dropdown>
            <div style="display: flex; align-items: center; cursor: pointer">
              <div
                style="
                  margin-right: 5px;
                  width: 100px;
                  text-align: center;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ templateName }}
              </div>
              <div class="el-icon-caret-bottom"></div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div
                class="singleObj objTitle"
                @click="choseTemplate(index, item.id)"
                v-for="(item, index) in templateList"
                :key="index"
                :style="{
                  background: item.name == templateName ? '#ECF5FF' : '#fff',
                  color: item.name == templateName ? '#7ac2fd' : '#333333',
                }"
              >
                {{ item.name }}
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 标题 -->
        <!-- 相关模板 lable.Related.template-->
        <div style="margin: 0px 20px">{{ $t("lable.Related.template") }}</div>
        <!-- 输入框 -->
        <div>
          <el-select v-model="parentObject" placeholder="" @change="tabObject">
            <el-option
              v-for="item in parentObjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        :class="[this.isFixed ? 'fixedDom' : '']"
        :style="{ width: this.DomWidth + 'px' }"
      >
        <!-- 按钮组 -->
        <!-- 保存 component.telerecord.button.save-->
        <!-- 快速保存 setup.layout.button.quicksave-->
        <!-- 取消 label.emailsync.button.cancel-->
        <div class="btnGroup">
          <el-button @click="saveReportTemplate">{{
            $t("component.telerecord.button.save")
          }}</el-button>
          <el-button @click="FastSave">{{
            $t("setup.layout.button.quicksave")
          }}</el-button>
          <el-button @click="cancelFun">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <span>|</span>
          <!-- 重做 lable.redo-->
          <el-button icon="el-icon-refresh-right" @click="redo">{{
            $t("lable.redo")
          }}</el-button>
        </div>
        <!-- 对象列表 -->
        <div class="dataList">
          <div class="objList">
            <div
              @click="updateObj(item)"
              class="objItem"
              v-for="(item, index) in objList"
              :key="index"
              :style="{
                background: item.objId == checkedObj ? '#E0E0E0' : '',
                fontWeight: item.objId == checkedObj ? 'bold' : '',
              }"
            >
              {{ item.tlabel }}
            </div>
          </div>
          <div class="data" v-loading="loadOption">
            <!-- 查找 -->
            <div class="search">
              <!-- 快速查找 lable.Quick.find-->
              <div>{{ $t("lable.Quick.find") }}</div>
              <div style="margin: 0px 20px">
                <!-- 字段 setup.layout.group.field-->
                <el-input
                  :placeholder="
                    checkedObjLabel + $t('setup.layout.group.field')
                  "
                  v-model="searchObjectValue"
                  @input="objectFieldIsempty"
                  @keyup.enter.native="searchObjectField"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-search"
                    @click="searchObjectField"
                  ></i>
                </el-input>
              </div>
              <div class="el-icon-warning"></div>
            </div>
            <!-- 列表 -->
            <div class="listF">
              <ul class="fieldList">
                <vuedraggable
                  v-model="ObjectField"
                  animation="300"
                  :options="groupType"
                  :disabled="disabled"
                  filter=".DisableInputItem"
                  @choose="chooseType"
                  @unchoose="unchooseType"
                  @end="isRepeat"
                  :move="NotField"
                >
                  <transition-group style="display: flex; flex-wrap: wrap">
                    <!-- isDisable 已经存在字段禁用-->
                    <!-- Listdisabled 如果是客户和联系人则禁用列表拖拽项-->
                    <li
                      :class="[
                        item.isDisable && item.id != '0001' && item.id != '0002'
                          ? 'DisableInputItem'
                          : 'inputItem',
                      ]"
                      v-for="(item, index) in ObjectField"
                      :key="index"
                      :title="item.labelName"
                      :id="[item.id == '0001' ? 'isList' : '']"
                    >
                      <!-- 箭头 -->
                      <i
                        v-if="item.id == '0001' || item.id == '0002'"
                        class="el-icon-right position"
                      ></i>
                      <div
                        v-if="item.id == '0001' || item.id == '0002'"
                        class="position"
                        style="left: 22px; top: 4px"
                      >
                        <svg class="icon" aria-hidden="true">
                          <use href="#icon-part"></use>
                        </svg>
                      </div>
                      <!-- <input
                    readonly
                    prefix-icon="el-icon-right"
                    v-model="item.labelName"
                    :class="[item.id == '0001' || item.id == '0002' ? 'backgroundColor' : '']"
                  /> -->
                      <div
                        :class="[
                          item.id == '0001' || item.id == '0002'
                            ? 'backgroundColor'
                            : '',
                          'fieldtext',
                        ]"
                      >
                        {{ item.labelName }}
                      </div>
                    </li>
                  </transition-group>
                </vuedraggable>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模块二 -->
    <div
      class="moduleT"
      :style="{ marginTop: this.isFixed ? '230px' : '15px' }"
    >
      <!-- 基础信息 -->
      <vuedraggable
        v-model="templateContentList"
        group="partList"
        animation="300"
      >
        <transition-group>
          <div
            class="templateContent"
            v-for="(item, index) in templateContentList"
            :key="index"
          >
            <!-- 标题 -->
            <div
              class="partTitle"
              @mouseover="partEnter(index)"
              @mouseleave="partLeave(index)"
            >
              <span
                class="contentTitle"
                :style="{
                  color: item.HideTitle != '1' ? '#666666' : '#dcdcdc',
                }"
                >{{ item.title }}</span
              >
              <span class="optionIcon" v-show="item.iconStatus">
                <i
                  class="el-icon-remove"
                  @click="removePart(index)"
                  v-show="item.iconStatus && templateContentList.length > 1"
                ></i>
                <i
                  class="el-icon-setting"
                  @click="settingPart(index, item)"
                ></i>
              </span>
            </div>
            <!-- 数据 -->
            <div class="contentData">
              <!--部分 左右布局 -->
              <div class="layoutBox" v-if="item.type == 'section'">
                <!-- 左边 -->
                <div class="layoutBox-left">
                  <vuedraggable
                    v-model="item.leftData"
                    animation="300"
                    group="fieldgroup"
                    @choose="chooseDisabledleft"
                    @unchoose="unchooseDisabledleft"
                  >
                    <transition-group>
                      <div
                        @mouseover="fieldListEnterLeft(index, Sindex)"
                        @mouseleave="fieldListLeaveLeft(index, Sindex)"
                        class="contentDataItem"
                        v-for="(Sitem, Sindex) in item.leftData"
                        :key="Sindex"
                      >
                        <div class="contentDataItem-child-left">
                          <div class="sampleText">
                            <span class="fieldName">{{ Sitem.labelName }}</span>
                            <!-- 示例文本 lable.sample.text-->
                            <span v-if="Sitem.id != 'isempty'">{{
                              $t("lable.sample.text")
                            }}</span>
                            <span></span>
                          </div>
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeLeftField(index, Sindex, Sitem.id)"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </transition-group>
                  </vuedraggable>
                </div>
                <!-- 右边 -->
                <div class="layoutBox-right">
                  <vuedraggable
                    v-model="item.rightData"
                    animation="300"
                    group="fieldgroup"
                    @choose="chooseDisabledright"
                    @unchoose="unchooseDisabledright"
                  >
                    <transition-group>
                      <div
                        @mouseover="fieldListEnterRight(index, Sindex)"
                        @mouseleave="fieldListLeaveRight(index, Sindex)"
                        class="contentDataItem"
                        v-for="(Sitem, Sindex) in item.rightData"
                        :key="Sindex"
                      >
                        <div class="contentDataItem-child-right">
                          <div class="sampleText">
                            <span class="fieldName">{{ Sitem.labelName }}</span>
                            <!-- 示例文本 lable.sample.text-->
                            <span v-if="Sitem.id != 'isempty'">{{
                              $t("lable.sample.text")
                            }}</span>
                            <span></span>
                          </div>
                          <span class="remove-field">
                            <i
                              class="el-icon-remove"
                              v-show="Sitem.id != 'isempty' && Sitem.isShow"
                              @click="removeRightField(index, Sindex, Sitem.id)"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </transition-group>
                  </vuedraggable>
                </div>
              </div>
              <!-- 列表 -->
              <div v-if="item.type == 'list'" class="listStyle">
                <table>
                  <tr>
                    <th v-for="listItem in item.tableData" :key="listItem.id">
                      {{ listItem.labelName }}
                    </th>
                  </tr>
                  <tr>
                    <!-- 示例文本 lable.sample.text-->
                    <td v-for="listTd in item.tableData" :key="listTd.id">
                      {{ $t("lable.sample.text") }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
      <!-- 电子签名 lable.electronic.signature-->
      <!-- 签署人： lable.Signatory-->
      <!-- 示例文本 lable.sample.text-->
      <div class="qianming">
        <div class="contentTitle">{{ $t("lable.electronic.signature") }}</div>
        <div class="contentData">
          <div class="contentDataItem">
            <div style="min-width: 80px">{{ $t("lable.Signatory") }}</div>
            <div>{{ $t("lable.sample.text") }}</div>
          </div>
          <div class="contentDataItem">
            <div style="min-width: 80px">{{ $t("label.date") + ":" }}</div>
            <div>{{ $t("lable.sample.text") }}</div>
          </div>
          <div class="contentDataItem">
            <div style="min-width: 80px">{{ $t("label.address") + ":" }}</div>
            <div>{{ $t("lable.sample.text") }}</div>
          </div>
          <div class="contentDataItem">
            <div style="min-width: 80px">
              {{ $t("label.quote.qianming") + ":" }}
            </div>
            <div>{{ $t("lable.sample.text") }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 部分弹窗 -->
    <!-- 标题 label.file.title-->
    <!-- 新建部分 label.layout.new.built-->
    <!-- 部分属性 lable.Partial.attributes-->
    <div>
      <el-dialog
        :title="$t('lable.Partial.attributes')"
        :visible.sync="partStatus"
        width="30%"
      >
        <span class="partDailog">
          <span>{{ $t("label.file.title") }}</span>
          <el-input
            v-model="partinput"
            :placeholder="$t('label.layout.new.built')"
          ></el-input>
        </span>
        <div class="titleHide">
          <!-- 隐藏标题 lable.Hide.title-->
          <el-checkbox v-model="PartIsHide">{{
            $t("lable.Hide.title")
          }}</el-checkbox>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 取消 label.emailsync.button.cancel-->
          <!-- 确定 label.tabpage.ok-->
          <el-button @click="partStatus = false">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="addPart">{{
            $t("label.tabpage.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 设置弹窗 -->
    <!-- 部分属性 lable.Partial.attributes-->
    <div>
      <el-dialog
        :title="$t('lable.Partial.attributes')"
        :visible.sync="settingStatus"
        width="30%"
      >
        <span class="partDailog">
          <!-- 标题 label.quote.title-->
          <span>{{ $t("label.quote.title") }}</span>
          <el-input
            v-model="settinginput"
            :placeholder="$t('label.layout.new.built')"
          ></el-input>
        </span>
        <div class="titleHide">
          <!-- 隐藏标题 lable.Hide.title-->
          <el-checkbox v-model="PartSetIsHide">{{
            $t("lable.Hide.title")
          }}</el-checkbox>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="settingStatus = false">{{
            $t("label.emailsync.button.cancel")
          }}</el-button>
          <el-button type="primary" @click="settingPartTitle">{{
            $t("label.tabpage.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <!-- 列表弹窗 -->
      <!-- 列表属性 lable.List.properties-->
      <el-dialog
        :title="$t('lable.List.properties')"
        :visible.sync="showList"
        width="780px"
        @close="closeDialog"
        @open="openDialog"
      >
        <div class="bodyContent">
          <!-- 标题 label.quote.title-->
          <div class="titlePart">
            <div class="bodyTitle">{{ $t("label.quote.title") + ":" }}</div>
            <div>
              <div>
                <el-input v-model="listName" placeholder=""></el-input>
              </div>
              <div>
                <!-- 隐藏标题 lable.Hide.title-->
                <el-checkbox v-model="ListIsHide">{{
                  $t("lable.Hide.title")
                }}</el-checkbox>
              </div>
            </div>
          </div>
          <!-- 列表 -->
          <div class="bodyList">
            <div class="listButton">
              <!-- 筛选器 label.weixin.report.filters-->
              <!-- 排序 label.sort-->
              <!-- 列数 lable.Number.of.columns-->
              <el-button @click="changeContent('columns')">{{
                $t("lable.Number.of.columns")
              }}</el-button>
              <el-button @click="changeContent('screen')">{{
                $t("label.weixin.report.filters")
              }}</el-button>
              <el-button @click="changeContent('sort')">{{
                $t("label.sort")
              }}</el-button>
            </div>
            <!-- 内容 -->
            <div class="content" v-if="showFilter == 'columns'">
              <!-- 标题 -->
              <!-- 选择一个对象并添加它的最多10个字段到表格中。上移或下移箭头作为列标题出现的字段。 lable.Up.to.ten.fields-->
              <div class="contentTitle">
                {{ $t("lable.Up.to.ten.fields") }}
              </div>
              <!-- 对象选择 -->
              <div class="titlePart">
                <!-- 对象 label.import.index.obj-->
                <!-- -无- label.h5.date.none-->
                <div class="bodyTitle">
                  {{ $t("label.import.index.obj") + ":" }}
                </div>
                <el-select
                  v-model="CurrentObject"
                  :placeholder="$t('label.h5.date.none')"
                  @change="getSubobjectFieldList"
                >
                  <el-option
                    v-for="item in Subobjectlist"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 穿梭框 -->
              <div>
                <!-- 标题 -->
                <!-- 显示字段 component.setup.label.displayfield-->
                <!-- 可选字段 label.select.field-->
                <div class="fieldTitle">
                  {{ $t("component.setup.label.displayfield") }}
                </div>
                <div>
                  <div class="hasList hasListLeft">
                    <div class="title">{{ $t("label.select.field") }}</div>
                    <div style="position: relative">
                      <div class="bodyS">
                        <div class="input-box">
                          <div class="inputTitle">
                            <input
                              style="width: 100%"
                              type="text"
                              @input="isempty"
                              @keyup.enter="searchField"
                              v-model="searchValue"
                            />
                          </div>
                          <svg
                            class="icon"
                            aria-hidden="true"
                            @click="searchField"
                            style="cursor: pointer"
                          >
                            <use href="#icon-search"></use>
                          </svg>
                        </div>
                        <div class="list">
                          <div
                            class="list-item"
                            v-for="(item, index) in searchSubobjectFieldList"
                            :key="item.id"
                            @mouseover="mouseOver(index)"
                            :style="{
                              background:
                                addCurrentIndex == index ? '#F5F5F5' : '#fff',
                              color:
                                addCurrentIndex == index
                                  ? '#006DCC'
                                  : '#333333',
                            }"
                          >
                            <div class="name">{{ item.labelName }}</div>
                            <div
                              class="symbol"
                              v-show="
                                addCurrentIndex == index &&
                                isSelectPeoList.length < 10
                              "
                              @click="selectPeo(item, index)"
                            >
                              <svg class="icon" aria-hidden="true">
                                <use href="#icon-xinjian-grey"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="hasList isSelect">
                    <!-- 已选字段 label.chosen.field-->
                    <div class="title">{{ $t("label.chosen.field") }}</div>
                    <div class="bodyS" style="padding-top: 0px">
                      <div class="list" style="padding-top: 0px">
                        <div
                          class="list-item"
                          v-for="(item, index) in isSelectPeoList"
                          :key="item.id"
                          @mouseover="isSelectMouseOver(index)"
                          :style="{
                            background:
                              deleteCurrentIndex == index ? '#F5F5F5' : '#fff',
                            color:
                              deleteCurrentIndex == index
                                ? '#006DCC'
                                : '#333333',
                          }"
                        >
                          <div class="name">{{ item.labelName }}</div>
                          <div
                            class="symbol"
                            v-show="deleteCurrentIndex == index"
                            @click="deletePeo(index)"
                          >
                            <svg class="icon" aria-hidden="true">
                              <use href="#icon-jianhao"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="PositionFunction">
                    <i class="el-icon-top" @click="FieldTopping"></i>
                    <i class="el-icon-arrow-up" @click="FieldMoveUp"></i>
                    <i class="el-icon-arrow-down" @click="FieldMoveDown"></i>
                    <i class="el-icon-bottom" @click="FieldBottomSetting"></i>
                  </div>
                </div>
              </div>
              <div style="clear: both"></div>
            </div>
            <div class="content" v-show="showFilter == 'screen'">
              <!-- 或者，筛选在相关列表中显示的记录。选择复选框，或选项列表字段和您想要筛选的值。如果您手动输入值，请使用逗号将其割开。 lable.Set.value.prompt-->
              <div class="contentTitle">
                {{ $t("lable.Set.value.prompt") }}
              </div>
              <div class="range">
                <!-- 筛选字段文本框 -->
                <el-form>
                  <el-form-item v-for="(val, index) in pushData" :key="val.id">
                    <div class="vl" style="display: flex">
                      <!-- 筛选字段 开始 -->
                      <!-- 选择字段 setup.layout.label.selectedfield-->
                      <el-select
                        v-model="val.fieldId"
                        :placeholder="$t('setup.layout.label.selectedfield')"
                        filterable
                        popper-class="option"
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="(items, indexs) in choseFieldList"
                          :key="indexs"
                          :label="items.labelName"
                          :value="items.id"
                          @click.native="handleCommand(val, items, items)"
                        >
                          <!-- val:传递当前字段的信息 -->
                          <!-- items:传递 -->
                        </el-option>
                      </el-select>
                      <!-- 筛选条件 开始 -->
                      <!-- 选择条件 label.report.select.condition-->
                      <el-select
                        v-model="val.op"
                        :placeholder="$t('label.report.select.condition')"
                        class="select"
                        filterable
                        popper-class="option"
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="item in conditions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <EditableCell
                      style="
                          display: inline-block;
                          width:160px
                          position: relative;
                        "
                      ref="editableCell"
                      :options="selector"
                      :value="val.val"
                      :input-type="val.inputType"
                      :max="1000000000000000000"
                      :origin-type="filter"
                      :prefix="prefix"
                      :fieldId="val.fieldId"
                      :objid="val.objid"
                      @selectes="val.fieldId === '' ? '' : selectes(val)"
                      @remoteSearch="remoteSearch(val, index)"
                      @remoteMethods="remoteMethods"
                    >
                      {{ val.fieldId }}
                    </EditableCell>
                    <!-- 加减按钮组 -->
                    <div style="display: flex; margin-left: 5px">
                      <el-button
                        type="info"
                        size="mini"
                        @click="updateItem('reduce', index)"
                        v-if="showBut[index]"
                        >-</el-button
                      >
                      <!-- 清空 label.emailtocloudcc.clear-->
                      <el-button
                        type="info"
                        size="mini"
                        @click="clear"
                        v-if="index == 0"
                        >{{ $t("label.emailtocloudcc.clear") }}</el-button
                      >
                      <!-- <button type=" button" @click="updateItem('reduce', index)" v-if="showBut[index]"></button> -->
                      <el-button
                        type="info"
                        size="mini"
                        @click="updateItem('add', index)"
                        v-if="
                          index + 1 == pushData.length && pushData.length <= 4
                        "
                        >+</el-button
                      >
                      <!-- <button type="button" @click="updateItem('add', index)" v-if="index + 1 == pushData.length">
                +
              </button> -->
                    </div>
                  </el-form-item>
                </el-form>
                <div>
                  <!-- 搜索 label.searchs-->
                  <el-dialog
                    :title="$t('label.searchs')"
                    custom-class="dialog-form-wrapper"
                    width="60%"
                    top="5%"
                    style="height: 91%"
                    :visible.sync="showSearchTable"
                    :lock-scroll="true"
                    :close-on-click-modal="false"
                    :modal="true"
                    append-to-body
                  >
                    <search-table
                      ref="searchTable"
                      :fieldId="fieldId"
                      :checked="checked"
                      :relevant-objid="relevantObjId"
                      :relevant-prefix="relevantPrefix"
                      @changeSelect="changeSelect"
                    />
                  </el-dialog>
                </div>
              </div>
            </div>
            <div v-if="showFilter == 'sort'" class="sort">
              <!-- 通过选择要排序的字段，自定义相关列表中的记录顺序。您可以按对象中的任何数字或日期字段进行排序。lable.Sort.field.tips -->
              <p>
                {{ $t("lable.Sort.field.tips") }}
              </p>
              <div>
                <!-- 排序方式 label.orderby-->
                <!-- -无- label.h5.date.none-->
                <span class="sortOrder">{{ $t("label.orderby") }}</span>
                <el-select
                  v-model="TimeTypeField"
                  :placeholder="$t('label.h5.date.none')"
                  @change="chioceValue"
                >
                  <el-option
                    v-for="item in TimeTypeFieldList"
                    :key="item.id"
                    :label="item.labelName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="mode"
                  :placeholder="$t('label.h5.date.none')"
                >
                  <el-option
                    v-for="item in modeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <!-- 按钮组 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="showList = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirm" type="primary" size="mini">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <div></div>
  </div>
</template>

<script>
import * as ReportTemplate from "./api.js";
import vuedraggable from "vuedraggable";
import inputType from "@/config/enumCode/inputType.js";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import operator from "@/config/enumCode/operator.js";
import { GetViewGetSelectValue, GetCurrencyInfo, getFieldList, getRecordType } from "./api";
import * as CommonObjApi from "./api";

export default {
  components: {
    EditableCell,
    vuedraggable,
  },
  data() {
    return {
      groupType: {
        group: {
          name: "fieldgroup",
          pull: "clone",
        },
      },
      disabled: false,
      templateList: [],
      showFilter: "columns",
      objList: [
        // { objId: "serviceappointment", tlabel: "服务预约" }, //serviceappointment
        // 工作订单行项目 label.service.work.order.line
        //工作订单 lable.PowerProcess.Workorder
        //资产 label.asset
        //客户 vue_label_normal_account_sf
        //联系人 label.tabpage.contactsz
        {
          objId: "WorkOrderLineItemObj",
          tlabel: this.$i18n.t("label.service.work.order.line"),
        },
        {
          objId: "WorkOrderstdobj02021",
          tlabel: this.$i18n.t("lable.PowerProcess.Workorder"),
        },
        { objId: "asset", tlabel: this.$i18n.t("label.asset") },
        {
          objId: "account",
          tlabel: this.$i18n.t("vue_label_normal_account_sf"),
        },
        { objId: "contact", tlabel: this.$i18n.t("label.tabpage.contactsz") },
      ],
      checkedObj: "WorkOrderLineItemObj",
      checkedObjLabel: this.$i18n.t("label.service.work.order.line"),
      fieldList: [],
      loadOption: true,
      showList: false,
      searchValue: "",
      searchObjectValue: "",
      deleteCurrentIndex: null,
      addCurrentIndex: null,
      isSelectPeoList: [],
      //父对象
      parentObject: "work",
      //父对象列表
      parentObjectOptions: [
        //工作订单行项目 label.service.work.order.line
        //工作订单行项目生成的服务预约 lable.Generate.service.appointment
        {
          label: this.$i18n.t("label.service.work.order.line"),
          value: "work",
        },
        {
          label: this.$i18n.t("lable.Generate.service.appointment"),
          value: "appo",
        },
      ],
      // 保存筛选字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ],
      pushDatas: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        inputType: "input",
      },
      //筛选条件
      choseFieldList: [],
      conditions: [],
      selector: [],
      saveLoading: true,
      filter: "filter",
      showBut: [false],
      showSearchTable: false,
      fieldId: "",
      checked: false, //多选框选择和取消
      relevantObjId: "",
      relevantPrefix: "",
      prefix: this.$route.query.prefix,
      templateContentList: [
        //新建部分 label.layout.new.built
        {
          title: this.$i18n.t("label.layout.new.built"),
          HideTitle: "0",
          leftData: [],
          rightData: [],
          iconStatus: false,
          type: "section",
        },
        {
          title: this.$i18n.t("label.layout.new.built"),
          HideTitle: "0",
          leftData: [],
          rightData: [],
          iconStatus: false,
          type: "section",
        },
        {
          title: this.$i18n.t("label.layout.new.built"),
          HideTitle: "0",
          leftData: [],
          rightData: [],
          iconStatus: false,
          type: "section",
        },
      ],
      templateName: "",
      //当前拖拽元素
      draggedContext: "",
      //部分弹窗状态码
      partStatus: false,
      //部分弹窗中输入框
      partinput: this.$i18n.t("label.layout.new.built"),
      //设置弹窗
      settinginput: "",
      //设置弹窗-状态
      settingStatus: false,
      //部分索引
      partIndex: 0,
      //列表标题
      //新建列表 lable.create.new.list
      listName: this.$i18n.t("lable.create.new.list"),
      //是否开启固定定位
      isFixed: false,
      DomWidth: 0,
      //子对象列表
      Subobjectlist: [],
      //子对象字段列表
      SubobjectFieldList: [],
      //搜索结果数组
      searchSubobjectFieldList: [],
      CurrentObject: "",
      //时间类型字段
      TimeTypeField: "",
      //时间类型字段列表
      TimeTypeFieldList: [],
      //排序
      mode: "",
      //排序类型
      modeList: [],
      //父对象字段列表
      ObjectField: [],
      //是否是编辑列表
      isEditor: false,
      //当前列表索引
      ListIndex: 0,
      //部分隐藏标题
      PartIsHide: false,
      //部分设置隐藏标题
      PartSetIsHide: false,
      //列表隐藏标题
      ListIsHide: false,
      reportid: "",
      //列表编辑下副对象id
      listEditId: "",
      //是否为快速保存
      isFastSave: false,
    };
  },
  mounted() {
    this.DomWidth = this.$refs.DomBody.offsetWidth - 20;
    this.$Bus.$on("Ceiling-suction", (scrollTop) => {
      this.isFixed = scrollTop > 70 ? true : false;
    });
    this.getAllReportTemplate();
    this.getObjLists(this.checkedObj);
    this.getobjlist(this.checkedObj);
    if (this.$route.query.Tid) {
      this.getTemplateDetail(this.$route.query.Tid, "work");
    }
    if (this.$route.query.Tname) {
      this.templateName = this.$route.query.Tname;
    }
  },
  created() {},
  methods: {
    //已选字段置顶
    FieldTopping() {
      if (this.deleteCurrentIndex) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.unshift(deleteField[0]);
        this.deleteCurrentIndex = 0;
      }
    },
    //已选字段置底
    FieldBottomSetting() {
      if (this.deleteCurrentIndex || this.deleteCurrentIndex == 0) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.push(deleteField[0]);
        this.deleteCurrentIndex = this.isSelectPeoList.length - 1;
      }
    },
    //已选字段向上移
    FieldMoveUp() {
      if (this.deleteCurrentIndex) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.splice(
          this.deleteCurrentIndex - 1,
          0,
          deleteField[0]
        );
        this.deleteCurrentIndex = this.deleteCurrentIndex - 1;
      }
    },
    //已选字段向下移
    FieldMoveDown() {
      if (this.deleteCurrentIndex == this.isSelectPeoList.length - 1) {
        return;
      }
      if (this.deleteCurrentIndex || this.deleteCurrentIndex == 0) {
        let deleteField = this.isSelectPeoList.splice(
          this.deleteCurrentIndex,
          1
        );
        this.isSelectPeoList.splice(
          this.deleteCurrentIndex + 1,
          0,
          deleteField[0]
        );
        this.deleteCurrentIndex = this.deleteCurrentIndex + 1;
      }
    },
    //重做
    redo() {
      this.getTemplateDetail(this.$route.query.Tid, this.parentObject);
    },
    //取消-返回模板列表
    cancelFun() {
      this.$router.push({
        path: "reportTemplate",
      });
    },
    //当前为快速保存
    FastSave() {
      this.isFastSave = true;
      this.saveReportTemplate();
    },
    //切换主对象时遍历字段列表-如果已经在部分列表中则禁用该字段
    DisableField() {
      this.templateContentList.forEach((item) => {
        if (item.type == "section") {
          item.leftData.forEach((val) => {
            this.ObjectField.forEach((FieldItem, index) => {
              if (val.id == FieldItem.id) {
                this.ObjectField[index].isDisable = true;
              }
            });
          });

          item.rightData.forEach((val) => {
            this.ObjectField.forEach((FieldItem, index) => {
              if (val.id == FieldItem.id) {
                this.ObjectField[index].isDisable = true;
              }
            });
          });
        }
      });
    },
    //获取单个服务报告模板详情
    async getTemplateDetail(id, type) {
      this.saveLoading = true;
      let resultData = await ReportTemplate.getTemplateById({
        id: id,
        type: type,
      });
      if (
        resultData.data.sectionList &&
        resultData.data.sectionList[0].reportid
      ) {
        this.reportid = resultData.data.sectionList[0].reportid;
      }
      if (resultData.result) {
        this.saveLoading = false;
        let editTemplateArr = [];
        resultData.data.sectionList.forEach((item) => {
          if (item.type == "section") {
            let obj = {};
            obj.title = item.name;
            obj.HideTitle = item.hidetitle;
            obj.iconStatus = false;
            obj.type = item.type;
            obj.leftData = [];
            obj.rightData = [];
            item.fieldList.forEach((val) => {
              let fieldObj = {};
              fieldObj.id = val.fieldid;
              fieldObj.labelName = val.fieldname;
              fieldObj.isShow = false;
              //部分左侧字段列表
              if (val.colIndex == 1) {
                obj.leftData.splice(val.rowIndex - 1, 0, fieldObj);
              }
              //部分右侧字段列表
              if (val.colIndex == 2) {
                obj.rightData.splice(val.rowIndex - 1, 0, fieldObj);
              }
            });
            editTemplateArr.splice(item.seq - 1, 0, obj);
          } else if (item.type == "list") {
            let listobj = {};
            listobj.title = item.name;
            listobj.HideTitle = item.hidetitle;
            listobj.iconStatus = false;
            listobj.type = item.type;
            listobj.MainObject = item.objid;
            listobj.objid = item.relatedlistid;
            listobj.tableData = [];
            item.fieldList.forEach((value) => {
              let fieldsObj = {};
              fieldsObj.id = value.fieldid;
              fieldsObj.labelName = value.fieldname;
              fieldsObj.type = value.schemefieldtype;
              listobj.tableData.splice(value.seq - 1, 0, fieldsObj);
            });
            listobj.OptionalField = item.unSelectFieldList;
            // ReportTemplate.getRelationList({ objId: item.objid })
            //   .then((objList) => {
            //     let SingleObject
            //     let allField
            //     objList.data.forEach((obj) => {
            //       if (obj.id == item.relatedlistid) {
            //         allField = obj.fieldList
            //         listobj.tableData.forEach((exists) => {
            //           allField.forEach((Field,index)=>{
            //               if(exists.id == Field.id){
            //                 allField.splice(index,1)
            //               }
            //           })
            //         })
            //       }
            //     })
            //     listobj.OptionalField = item.unSelectFieldList;

            //   })
            //   .catch(() => {})
            //筛选器回显
            let copydata = [];
            let allField = listobj.tableData.concat(item.unSelectFieldList);
            if (item.condList.length != 0) {
              item.condList.forEach((ele, index) => {
                copydata.push({
                  id: index,
                  fieldId: ele.fieldId,
                  op: ele.operator,
                  val: {
                    value: ele.value,
                  },
                  inputType: "input",
                });
              });
              copydata.forEach((nav) => {
                allField.forEach((em) => {
                  if (nav.fieldId == em.id) {
                    nav.type = em.type;
                    nav.inputType = inputType[em.type];
                    this.conditions =
                      operator.TYPE[nav.type].getScreeningOpeTypes();
                  }
                });
              });
              if (item.condList.length >= 0 && item.condList.length <= 1) {
                this.showBut = [false];
              } else {
                this.showBut.push(true);
                this.showBut[0] = true;
              }
            } else {
              let copyobj = [
                {
                  id: 0,
                  fieldId: "",
                  op: "",
                  val: {
                    value: "",
                  },
                  inputType: "input",
                },
              ];
              copydata = copyobj;
            }
            listobj.Filter = copydata;
            let sortObj = {};
            if (item.sortfield) {
              sortObj.sortField = item.sortfield;
            } else {
              sortObj.sortField = "";
            }

            if (item.sort) {
              sortObj.sortorder = item.sort;
            } else {
              sortObj.sortorder = "";
            }
            listobj.sort = sortObj;
            editTemplateArr.splice(item.seq - 1, 0, listobj);
          }
        });
        this.templateContentList = editTemplateArr;
        this.DisableField();
      }
    },
    //获取全部服务报告模板
    async getAllReportTemplate() {
      let resultData = await ReportTemplate.getAllTemplate({ id: "" });
      resultData.data.forEach((item) => {
        if (item.isenable == "1") {
          item.isenable = true;
        } else {
          item.isenable = false;
        }
      });
      this.templateList = resultData.data;
    },
    //保存服务报表模板
    async saveReportTemplate() {
      this.saveLoading = true;
      let params = {};
      let templateContentList = JSON.parse(
        JSON.stringify(this.templateContentList)
      );
      let Splicing = [];
      templateContentList.forEach((item, index) => {
        //如果是部分-拼参数
        if (item.type == "section") {
          let obj = {};
          obj.hidetitle = item.HideTitle;
          obj.reportid = this.reportid;
          obj.name = item.title;
          obj.type = "section";
          obj.cols = "2";
          obj.seq = index + 1;
          let fieldList = [];
          item.leftData.forEach((fieldleft, fieldleftIndex) => {
            let fieldObj = {};
            fieldObj.colIndex = 1;
            fieldObj.rowIndex = fieldleftIndex + 1;
            fieldObj.reportid = this.reportid;
            fieldObj.seq = fieldleftIndex + 1;
            fieldObj.fieldid = fieldleft.id;
            fieldList.push(fieldObj);
          });
          item.rightData.forEach((fieldleft, fieldleftIndex) => {
            let fieldObj = {};
            fieldObj.colIndex = 2;
            fieldObj.rowIndex = fieldleftIndex + 1;
            fieldObj.reportid = this.reportid;
            fieldObj.seq = fieldleftIndex + 1;
            fieldObj.fieldid = fieldleft.id;
            fieldList.push(fieldObj);
          });
          obj.fieldList = fieldList;
          Splicing.push(obj);
          //如果是列表-拼参数
        } else if (item.type == "list") {
          let obj = {};
          obj.hidetitle = item.HideTitle;
          obj.reportid = this.reportid;
          obj.name = item.title;
          obj.relatedlistid = item.objid;
          obj.objid = item.MainObject;
          obj.sortfield = item.sort.sortField;
          obj.sort = item.sort.sortorder;
          obj.type = "list";
          obj.seq = index + 1;
          let copydata = [];
          //筛选器
          if (item.Filter.length > 0) {
            for (var key in item.Filter) {
              var items = item.Filter[key];
              if (items.fieldId !== "") {
                if (items.val.value == "" || items.op == "") {
                  return false;
                } else if (items.val.value != "" && items.op != "") {
                  let paramsObj = {};
                  paramsObj.fieldId = items.fieldId;
                  paramsObj.operator = items.op;
                  if (items.val.value != "") {
                    if (Array.isArray(items.val)) {
                      items.val = items.val.toString();
                    } else {
                      items.val = items.val.value;
                    }
                    paramsObj.value = items.val;
                  }
                  paramsObj.seq = parseInt(key) + 1;
                  copydata.push(paramsObj);
                }
              }
            }
            obj.condList = copydata;
          } else {
            obj.condList = "";
          }
          let fieldList = [];
          item.tableData.forEach((value, index) => {
            let fieldObj = {};
            fieldObj.colIndex = index + 1;
            fieldObj.rowIndex = 1;
            fieldObj.reportid = this.reportid;
            fieldObj.seq = index + 1;
            fieldObj.fieldid = value.id;
            fieldList.push(fieldObj);
          });
          obj.fieldList = fieldList;
          Splicing.push(obj);
        }
      });
      //签名-固定的
      //电子签名 lable.electronic.signature
      let signatureObj = {};
      signatureObj.hidetitle = "0";
      signatureObj.reportid = this.reportid;
      signatureObj.name = this.$i18n.t("lable.electronic.signature");
      signatureObj.type = "signature";
      signatureObj.cols = "1";
      signatureObj.seq = this.templateContentList.length + 1;
      signatureObj.fieldList = [
        {
          colIndex: 1,
          rowIndex: 1,
          reportid: this.reportid,
          seq: 1,
          fieldid: "ffe21signatory",
        },
        {
          colIndex: 1,
          rowIndex: 2,
          reportid: this.reportid,
          seq: 2,
          fieldid: "ffe21signaturedate",
        },
        {
          colIndex: 1,
          rowIndex: 3,
          reportid: this.reportid,
          seq: 3,
          fieldid: "ffe21signature",
        },
      ];
      Splicing.push(signatureObj);
      params.data = Splicing;
      params.name = this.$route.query.Tname;
      let resultData = await ReportTemplate.saveTemplate(params);
      if (resultData.result) {
        this.saveLoading = false;
        if (!this.isFastSave) {
          this.$router.push({
            path: "reportTemplate",
          });
          this.isFastSave = false;
        } else {
          this.isFastSave = false;
          return;
        }
      }
    },
    //主对象切换
    tabObject(val) {
      // { objId: "serviceappointment", tlabel: "服务预约" }, //serviceappointment
      // 工作订单行项目 label.service.work.order.line
      //工作订单 lable.PowerProcess.Workorder
      //资产 label.asset
      //客户 vue_label_normal_account_sf
      //联系人 label.tabpage.contactsz
      let workList = [
        {
          objId: "WorkOrderLineItemObj",
          tlabel: this.$i18n.t("label.service.work.order.line"),
        },
        {
          objId: "WorkOrderstdobj02021",
          tlabel: this.$i18n.t("lable.PowerProcess.Workorder"),
        },
        { objId: "asset", tlabel: this.$i18n.t("label.asset") },
        {
          objId: "account",
          tlabel: this.$i18n.t("vue_label_normal_account_sf"),
        },
        { objId: "contact", tlabel: this.$i18n.t("label.tabpage.contactsz") },
      ];
      //服务预约 label.projectManagement.service.appointment
      let appoList = [
        {
          objId: "serviceappointment",
          tlabel: this.$i18n.t("label.projectManagement.service.appointment"),
        },
        {
          objId: "WorkOrderLineItemObj",
          tlabel: this.$i18n.t("label.service.work.order.line"),
        },
        {
          objId: "WorkOrderstdobj02021",
          tlabel: this.$i18n.t("lable.PowerProcess.Workorder"),
        },
        { objId: "asset", tlabel: this.$i18n.t("label.asset") },
        {
          objId: "account",
          tlabel: this.$i18n.t("vue_label_normal_account_sf"),
        },
        { objId: "contact", tlabel: this.$i18n.t("label.tabpage.contactsz") },
      ];
      if (val == "appo") {
        this.objList = appoList;
        this.checkedObjLabel = this.$i18n.t(
          "label.projectManagement.service.appointment"
        );
        this.checkedObj = "serviceappointment";
        this.loadOption = true;
        this.getObjLists("serviceappointment");
        this.getobjlist("serviceappointment");
        this.getTemplateDetail(this.$route.query.Tid, val);
      } else if (val == "work") {
        this.objList = workList;
        this.checkedObjLabel = this.$i18n.t("label.service.work.order.line");
        this.checkedObj = "WorkOrderLineItemObj";
        this.loadOption = true;
        this.getObjLists("WorkOrderLineItemObj");
        this.getobjlist("WorkOrderLineItemObj");
        this.getTemplateDetail(this.$route.query.Tid, val);
      }
    },
    //列表弹窗开启
    openDialog() {
      this.showFilter = "columns";
    },
    //列表弹窗关闭
    closeDialog() {
      //是否编辑为false
      this.isEditor = false;
      this.clearList();
    },
    //对象字段为空
    objectFieldIsempty() {
      if (this.searchObjectValue == "") {
        this.ObjectField = this.fieldList;
        this.DisableField();
      }
    },
    //搜索对象字段列表
    searchObjectField() {
      if (this.searchObjectValue == "") {
        this.ObjectField = this.fieldList;
      } else {
        this.ObjectField = this.fieldList.filter((val) => {
          if (val.labelName) {
            return val.labelName.includes(this.searchObjectValue);
          }
        });
      }
      this.DisableField();
    },
    //可选字段搜索清空后重新赋值
    isempty() {
      if (this.searchValue == "") {
        let copyArr = JSON.parse(JSON.stringify(this.SubobjectFieldList));
        this.isSelectPeoList.forEach((item) => {
          copyArr.forEach((value, Sindex) => {
            if (item.id == value.id) {
              copyArr.splice(Sindex, 1);
            }
          });
        });
        this.searchSubobjectFieldList = copyArr;
      }
    },
    //可选字段搜索
    searchField() {
      if (this.searchValue == "") {
        this.isempty();
      } else {
        let copyArr = JSON.parse(JSON.stringify(this.isSelectPeoList));
        this.searchSubobjectFieldList = JSON.parse(
          JSON.stringify(this.SubobjectFieldList)
        ).filter((item) => {
          return item.labelName.includes(this.searchValue);
        });
        copyArr.forEach((Field) => {
          this.searchSubobjectFieldList.forEach((val, index) => {
            if (Field.id == val.id) {
              this.searchSubobjectFieldList.splice(index, 1);
            }
          });
        });
      }
    },
    chioceValue() {
      //升序 setup.layout.label.asc
      //降序 setup.layout.label.desc
      let list = [
        {
          value: "asc",
          label: this.$i18n.t("setup.layout.label.asc"),
        },
        {
          value: "desc",
          label: this.$i18n.t("setup.layout.label.desc"),
        },
      ];
      this.modeList = list;
    },
    //获取子对象字段列表
    getSubobjectFieldList(id) {
      this.TimeTypeField = "";
      this.mode = "";
      this.modeList = [];
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
      this.Subobjectlist.forEach((item) => {
        if (item.id == id) {
          this.SubobjectFieldList = item.fieldList;
          this.choseFieldList = item.fieldList;
          this.searchField();
          this.TimeTypeFieldList = item.fieldList.filter((val) => {
            return (
              val.schemefieldType == "D" ||
              val.schemefieldType == "N" ||
              val.schemefieldType == "F"
            );
          });
          this.isSelectPeoList = [];
        }
      });
    },
    //获取对象列表
    async getobjlist(objId) {
      if (objId == "account" || objId == "contact") {
        return;
      } else {
        let resultData = await ReportTemplate.getRelationList({ objId: objId });
        if (resultData.result) {
          this.Subobjectlist = resultData.data;
          if (this.isEditor) {
            this.CurrentObject = this.listEditId;
          }
        }
      }
    },
    //设置弹窗更改标题
    settingPartTitle() {
      this.templateContentList[this.partIndex].title = this.settinginput;
      this.settingStatus = false;
      if (this.PartSetIsHide) {
        this.templateContentList[this.partIndex].HideTitle = "1";
      } else {
        this.templateContentList[this.partIndex].HideTitle = "0";
      }
    },
    //设置弹窗
    settingPart(index, item) {
      if (item.type == "section") {
        this.settinginput = item.title;
        this.PartSetIsHide = item.HideTitle == "1" ? true : false;
        this.settingStatus = true;
        this.partIndex = index;
      } else if (item.type == "list") {
        this.ListIndex = index;
        //是否编辑为true
        this.isEditor = true;
        let copyItem = JSON.parse(JSON.stringify(item));
        this.listName = copyItem.title;
        this.ListIsHide = copyItem.HideTitle == "1" ? true : false;
        this.listEditId = copyItem.objid;
        this.getobjlist(copyItem.MainObject);
        this.getSubobjectFieldList(copyItem.objid);
        if (copyItem.OptionalField) {
          this.SubobjectFieldList = copyItem.OptionalField;
          copyItem.tableData.forEach((value) => {
            copyItem.OptionalField.forEach((val, index) => {
              if (value.id == val.id) {
                copyItem.OptionalField.splice(index, 1);
              }
            });
          });
          this.searchSubobjectFieldList = copyItem.OptionalField;
        }
        this.isSelectPeoList = copyItem.tableData;
        this.pushData = copyItem.Filter;
        this.TimeTypeField = copyItem.sort.sortField;
        //如果排序字段不为空则赋值选项
        if (this.TimeTypeField != "") {
          this.chioceValue();
        }
        this.mode = copyItem.sort.sortorder;
        this.showList = true;
      }
    },
    //移除当前部分
    removePart(index) {
      this.templateContentList.splice(index, 1);
    },
    //鼠标移入部分标题
    partEnter(index) {
      this.templateContentList[index].iconStatus = true;
    },
    //鼠标离开部分标题
    partLeave(index) {
      this.templateContentList[index].iconStatus = false;
    },
    //新增部分
    addPart() {
      let obj = {
        title: this.partinput,
        HideTitle: this.PartIsHide ? "1" : "0",
        leftData: [],
        rightData: [],
        iconStatus: false,
        type: "section",
      };
      this.templateContentList.splice(this.draggedContext.futureIndex, 0, obj);
      this.partStatus = false;
      this.PartSetIsHide = false;
      //新建部分 label.layout.new.built
      this.partinput = this.$i18n.t("label.layout.new.built");
    },
    //当前拖动目标为列表或部分
    NotField(e) {
      if (
        e.draggedContext.element.id == "0001" ||
        e.draggedContext.element.id == "0002"
      ) {
        this.draggedContext = e.draggedContext;
      }
    },
    //移除左侧字段
    removeLeftField(index, Sindex, id) {
      this.templateContentList[index].leftData.splice(Sindex, 1);
      this.ObjectField.forEach((item) => {
        if (item.id == id) {
          item.isDisable = false;
        }
      });
      // this.ReduceHeight();
    },
    //移除右侧字段
    removeRightField(index, Sindex, id) {
      this.templateContentList[index].rightData.splice(Sindex, 1);
      this.ObjectField.forEach((item) => {
        if (item.id == id) {
          item.isDisable = false;
        }
      });
      // this.ReduceHeight();
    },
    //鼠标移入左侧模板列表
    fieldListEnterLeft(index, Sindex) {
      this.templateContentList[index].leftData[Sindex].isShow = true;
    },
    //鼠标移出左侧模板列表
    fieldListLeaveLeft(index, Sindex) {
      this.templateContentList[index].leftData[Sindex].isShow = false;
    },
    //鼠标移入右侧模板列表
    fieldListEnterRight(index, Sindex) {
      this.templateContentList[index].rightData[Sindex].isShow = true;
    },
    //鼠标移出右侧模板列表
    fieldListLeaveRight(index, Sindex) {
      this.templateContentList[index].rightData[Sindex].isShow = false;
    },
    //拖拽完成事件-标记被拖拽元素已存在，在字段列表中禁用
    isRepeat(e) {
      //当前拖拽的是部分-删除当前项、记录索引、打开弹窗
      //部分 label.sectionpart
      if (e.item.innerText == this.$i18n.t("label.sectionpart")) {
        this.templateContentList.splice(this.draggedContext.futureIndex, 1);
        this.partStatus = true;
      }
      //当前拖拽的是列表-删除当前项、记录索引、打开弹窗
      //列表 label.chatter.list
      //新建列表 lable.create.new.list
      if (e.item.innerText == this.$i18n.t("label.chatter.list")) {
        this.templateContentList.splice(this.draggedContext.futureIndex, 1);
        this.listName = this.$i18n.t("lable.create.new.list");
        this.CurrentObject = "";
        this.SubobjectFieldList = [];
        this.isSelectPeoList = [];
        this.showList = true;
        this.getobjlist(this.checkedObj);
      }
      this.templateContentList.forEach((item) => {
        if (item.leftData) {
          item.leftData.forEach((val) => {
            if (e.item.innerText == val.labelName) {
              this.ObjectField[e.oldIndex].isDisable = true;
            }
          });
        }
        if (item.rightData) {
          item.rightData.forEach((val) => {
            if (e.item.innerText == val.labelName) {
              this.ObjectField[e.oldIndex].isDisable = true;
            }
          });
        }
      });
      this.EqualHeightFun();
    },
    //部分模块中-字段列表中增加字段动态增加高度
    
    EqualHeightFun() {
      let FatherHeight = document.getElementsByClassName("layoutBox");
      let LeftHeight = FatherHeight[0].getElementsByClassName("layoutBox-left");
      let RightHeight =
        FatherHeight[0].getElementsByClassName("layoutBox-right");
      LeftHeight[0].style.minHeight = FatherHeight[0].clientHeight + "px";
      RightHeight[0].style.minHeight = FatherHeight[0].clientHeight + "px";
    },
    
    ReduceHeight() {
      let FatherHeight = document.getElementsByClassName("layoutBox");
      let LeftHeight = FatherHeight[0].getElementsByClassName("layoutBox-left");
      let RightHeight =
        FatherHeight[0].getElementsByClassName("layoutBox-right");
      if (LeftHeight[0].clientHeight >= RightHeight[0].clientHeight) {
        FatherHeight[0].style.height = LeftHeight[0].clientHeight + "px";
      } else {
        FatherHeight[0].style.height = RightHeight[0].clientHeight + "px";
      }
    },
    //字段列表拖拽前点击事件
    chooseType(e) {
      this.disabled = true;
      this.PartIsHide = false;
      this.ListIsHide = false;
      //如果当前选中项为部分,则更改拖拽组件group值
      if (
        e.item.innerText == this.$i18n.t("label.chatter.list") ||
        e.item.innerText == this.$i18n.t("label.sectionpart")
      ) {
        this.groupType.group.name = "partList";
      } else {
        this.groupType.group.name = "fieldgroup";
      }
      //拖拽组件初始状态数组不能为空，否则拖拽失效，为空的情况下向数组添加一个无意义的对象，只要有值就删除此空对象
      this.templateContentList.forEach((item) => {
        if (item.leftData && item.leftData.length == 0) {
          let obj = {
            id: "isempty",
          };
          item.leftData.push(obj);
        } else if (item.leftData && item.leftData.length > 1) {
          item.leftData = item.leftData.filter((item) => {
            return item.id != "isempty";
          });
        }

        if (item.rightData && item.rightData.length == 0) {
          let obj = {
            id: "isempty",
          };
          item.rightData.push(obj);
        } else if (item.rightData && item.rightData.length > 1) {
          item.rightData = item.rightData.filter((item) => {
            return item.id != "isempty";
          });
        }
      });
    },
    //字段列表选中后松开事件
    unchooseType() {
      this.disabled = false;
    },
    //拖拽目标列表松开选中事件
    unchooseDisabledleft() {
      this.disabled = false;
    },
    chooseDisabledleft() {
      this.disabled = true;
    },
    unchooseDisabledright() {
      this.disabled = false;
    },
    chooseDisabledright() {
      this.disabled = true;
    },
    choseTemplate(index, id) {
      this.getTemplateDetail(id, this.parentObject);
      this.templateName = this.templateList[index].name;
    },
    
    choseObj(item, index) {
      this.checkedObjLabel = this.objList[index].name;
      this.updateObj(item);
    },
    //新增列表
    confirm() {
      if (this.isSelectPeoList.length != 0) {
        let copyFilter = JSON.parse(JSON.stringify(this.pushData));
        let obj = {
          //标题
          title: this.listName,
          //隐藏标题
          HideTitle: this.ListIsHide ? "1" : "0",
          //主对象id
          MainObject: this.checkedObj,
          //副对象id
          objid: this.CurrentObject,
          //可选字段
          OptionalField: this.SubobjectFieldList,
          //已选字段
          tableData: this.isSelectPeoList,
          //图标状态
          iconStatus: false,
          //类型
          type: "list",
          //过滤器
          Filter: copyFilter,
          //排序
          sort: {
            sortField: this.TimeTypeField,
            sortorder: this.mode,
          },
        };
        //过滤器赋值
        if (this.$refs.editableCell) {
          this.$refs.editableCell.forEach((item, index) => {
            if (item.editValue !== "") {
              obj.Filter[index].val.value = item.editValue;
            } else {
              obj.Filter[index].value = item.value;
            }
          });
        }
        //如果是编辑则删除当前项，并重新赋值
        if (this.isEditor) {
          this.templateContentList.splice(this.ListIndex, 1);
          this.templateContentList.splice(this.ListIndex, 0, obj);
        } else {
          this.templateContentList.splice(
            this.draggedContext.futureIndex,
            0,
            obj
          );
        }
        this.showList = false;
        this.clearList();
      } else {
        this.$message.error(this.$i18n.t("label.empty.field"));
      }
    },
    //清除列表数据
    //新建列表 lable.create.new.list
    
    clearList() {
      this.listName = this.$i18n.t("lable.create.new.list");
      this.TimeTypeField = "";
      this.mode = "";
      this.modeList = [];
      this.SubobjectFieldList = [];
      this.searchSubobjectFieldList = [];
      this.isSelectPeoList = [];
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
    },
    
    delDialogVisible() {
      this.showList = false;
    },
    changeContent(data) {
      this.showFilter = data;
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      // let value = {
      //   value: row.data.id,
      // };
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.pushData[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    remoteSearch(item, index) {
      this.rowIndex = index + 1;
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    remoteMethods(val, fieldId, objId) {
      let params = {
        fieldId: fieldId,
        objId: objId,
        prefix: this.prefix,
      };
      CommonObjApi.getLookupInfo(params).then(() => {
        let data = {
          name: val,
          objId: params.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selector = options;
        });
      });
    },
    //获取对象字段列表
    async getObjLists(objId) {
      let obj = { objId };
      let result = await ReportTemplate.getFieldList(obj);
      if (result.data) {
        result.data.forEach((item, index) => {
          item.isDisable = false;
          item.isShow = false;
          if (!item.labelName && !typeof item.labelName != "undefined") {
            result.data.splice(index, 1);
          }
        });
        this.fieldList = result.data;
        if (objId == "account" || objId == "contact") {
          this.fieldList.unshift({
            id: "0002",
            labelName: this.$i18n.t("label.sectionpart"),
          });
        } else {
          this.fieldList.unshift({
            id: "0001",
            labelName: this.$i18n.t("label.chatter.list"),
          });
          this.fieldList.splice(6, 0, {
            id: "0002",
            labelName: this.$i18n.t("label.sectionpart"),
          });
        }
        this.loadOption = false;
        this.searchObjectField();
        this.DisableField();
      }
    },
    updateObj(item) {
      this.loadOption = true;
      this.checkedObj = item.objId;
      this.checkedObjLabel = item.tlabel;
      this.getObjLists(this.checkedObj);
      this.getobjlist(item.objId);
    },
    //删除当前选中的对象
    deletePeo(index) {
      var result = this.isSelectPeoList.splice(index, 1);
      let num = this.searchSubobjectFieldList.findIndex((res) => {
        res.id == result[0].id;
      });

      if (num < 0) {
        this.searchSubobjectFieldList.push(result[0]);
      }
    },
    //选择搜索对象
    selectPeo(item) {
      this.searchSubobjectFieldList.map((Sitem, Sindex) => {
        if (Sitem.id == item.id) {
          this.searchSubobjectFieldList.splice(Sindex, 1);
        }
      });
      let index = this.isSelectPeoList.findIndex((res) => res.id == item.id);
      if (index < 0) {
        this.isSelectPeoList.push(item);
      }
    },
    //鼠标移入
    isSelectMouseOver(index) {
      this.deleteCurrentIndex = index;
    },
    //鼠标移入
    mouseOver(index) {
      this.addCurrentIndex = index;
    },
    //清空筛选器数据
    clear() {
      this.pushData = [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
        },
      ];
    },
    //获取对象字段列表
    
    async getobjfieldList(id) {
      let fieldlist = await getFieldList(id);
      this.timeField = fieldlist.data.filter((item) => {
        return item.type == "F";
      });
    },
    //判断选择添加如果选择字段获取的值为S则调用S对象,获取的值为D则调用D对象,获取的值为F则调用F对象,获取的值为O则调用O对象
    async handleCommand(item, key, index) {
      this.selector = [];
      let keys = "";
      if (key.schemefieldType === "Z") {
        keys = key.expressionType;
      } else {
        keys = key.schemefieldType;
      }
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      this.pushData.map((item) => {
        if (index.id === item.fieldId) {
          key.schemefieldType === "Z"
            ? key.expressionType
            : key.schemefieldType;
        }
      });
      this.pushData.forEach((items) => {
        if (item.id === items.id) {
          item.inputType = inputType[keys];
        }
      });
      // 当前选中字段类型
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      if (keys == "ct") {
        let res = await GetCurrencyInfo();
        res.data.forEach((item) => {
          let obj = {
            label: item.currencyname,
            value: item.currencycode,
          };
          this.selector.push(obj);
        });
      }
    },
    updateItem(handle, index) {
      if (handle == "add") {
        if (this.pushData.length <= 4) {
          this.pushData.push({
            ...this.pushDatas
          });
          if (this.pushData.length >= 0 && this.pushData.length <= 1) {
            this.showBut = [false];
          } else {
            this.showBut.push(true);
            this.showBut[0] = true;
          }
        }
      } else {
        this.pushData.splice(index, 1);
        // this.$refs.editableCell.splice(index, 1);
      }
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      }
      else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selector = arr;
          });
        }
      }
    },
    // 获取国家或地区选项列表值
    
    getCountrySelectValue() {
      GetViewGetSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selector = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    
    async getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
          this.selector = arr;
        });
      }
    },
  },
  watch: {
    pushData(val) {
      if (val.length == 1) {
        this.showBut[0] = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
}
.range {
  .Requiredtype:first-child {
    color: red;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  ::v-deep .created-type {
    width: 120px;
    margin-right: 6px;
  }
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-date-editor {
    display: none !important;
  }
}
.PositionFunction {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  width: 40px;
  i {
    padding: 15px 5px;
    font-size: 20px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    cursor: pointer;
  }
  i:first-child {
    border-top: 1px solid #d8d8d8;
  }
  i:last-child {
    border-bottom: 1px solid #d8d8d8;
  }
}
.sort {
  padding: 15px 10px;
  p {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .sortOrder {
    margin-right: 15px;
  }
}

.fixedDom {
  position: fixed;
  top: 79px;
  z-index: 999;
  background-color: #f0f0f0;
  border: 1px solid #dcdcdc;
}
.listStyle {
  padding: 10px 10px;
  table {
    border-collapse: collapse;
    th,
    td {
      padding: 10px;
    }
    th {
      background-color: #f0f0f0;
      font-size: 14px;
      color: #333333;
      max-width: 10%;
    }
    td {
      font-size: 14px;
      color: #333333;
      background-color: #fff;
      max-width: 10%;
    }
  }
  table,
  th {
    border: 1px solid #dcdcdc;
  }
}
.layoutBox {
  display: flex;
  align-items: center;
  height: 100%;
  .layoutBox-left {
    width: 50%;
    min-height: 120px;
    .contentDataItem-child-left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fieldName {
        width: 139px;
        display: inline-block;
        text-align: left;
        overflow: hidden;
        cursor: move;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .sampleText {
        cursor: move;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .remove-field {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 20px;
      }
    }
  }
  .layoutBox-right {
    width: 50%;
    min-height: 120px;
    .contentDataItem-child-right {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fieldName {
        width: 139px;
        display: inline-block;
        text-align: left;
        overflow: hidden;
        cursor: move;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .sampleText {
        cursor: move;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .remove-field {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 20px;
      }
    }
  }
}
.partTitle {
  height: 30px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .contentTitle {
    margin-bottom: 0;
  }
  .optionIcon {
    i {
      cursor: pointer;
    }
    i:last-child {
      margin-left: 5px;
    }
  }
}
.partDailog {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 10px;
  }
}
.titleHide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.contentDataItem {
  padding: 10px;
  display: flex;
  height: 30px;
}
.contentDataItem > div:nth-child(1) {
  margin-right: 10px;
}
.contentData {
  background: #ffffff;
  border-radius: 3px;
  margin-bottom: 10px;
  min-height: 120px;
  padding-bottom: 10px;
}
.contentTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.moduleT {
  border-radius: 3px;
  padding: 10px;
  background: #dcdcdc;
}
.templateContent {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 3px solid #006dcc;
}
.titleArea {
  width: 100%;
  height: 50px;
  border-radius: 3px 3px 0px 0px;
  border-bottom: 1px solid #dcdcdc;
  align-items: center;
}
.body {
  padding: 19px 10px;
}
.setting {
  background: #f0f0f0;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}
.btnGroup {
  display: flex;
  height: 50px;
  padding: 12px 13px 12px 13px;
  border-bottom: 1px solid #dcdcdc;
}
.btnGroup span {
  margin: 0px 20px 0px 10px;
}
::v-deep .el-button {
  line-height: 0px;
}
::v-deep [class^="el-icon-"] {
  line-height: 0;
}
.dataList {
  display: flex;
  max-height: 163px;
}
.objList {
  width: 14%;
  border-right: 1px solid #dcdcdc;
  padding-bottom: 5px;
  overflow-y: auto;
}
.data {
  width: 86%;
  padding: 14px 13px;
  overflow-y: auto;
}
.objItem {
  margin-top: 5px;
  height: 30px;
  line-height: 30px;
  padding-left: 13px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.search {
  display: flex;
  align-items: center;
}
::v-deep .el-input__prefix {
  top: 2px;
}
::v-deep .el-input__inner {
  height: 24px;
  font-size: 12px;
  cursor: pointer;
}
::v-deep .el-input {
  width: 190px;
}
::v-deep .el-icon-warning:before {
  color: #006dcc;
}
::v-deep .el-input__icon {
  line-height: 24px;
}
.listF {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.inputItem {
  position: relative;
  margin-right: 7px;
  margin-bottom: 7px;
  font-size: 14px;
  width: 139px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  cursor: move;
}
.DisableInputItem {
  position: relative;
  margin-right: 7px;
  margin-bottom: 7px;
  font-size: 14px;
  width: 139px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f5f5f5;
  color: #aca899;
}
.fieldtext {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  padding: 4px 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input:focus {
  outline: none;
}
.backgroundColor {
  background: linear-gradient(180deg, #ffffff 1%, #cbe7ff 96%);
  padding-left: 40px;
}
.position {
  position: absolute;
  left: 6px;
  top: 16px;
}
.position img {
  width: 16px;
  height: 16px;
}
.bodyContent {
  padding: 36px;
}
.titlePart {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.bodyTitle {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.bodyList {
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.listButton {
  padding: 5px 8px;
  border-bottom: 1px solid #dcdcdc;
  background: #f0f0f0;
}
.fieldTitle {
  font-size: 12px;
  color: #080707;
  margin-bottom: 10px;
}
.content {
  padding: 14px 8px;
}
.contentTitle {
  font-size: 14px;
  color: #666666;
  margin-bottom: 10px;
}
::v-deep .singleObj {
  padding: 7px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.objTitle {
  cursor: pointer;
  font-size: 12px;
}
.objTitle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
::v-deep .el-button {
  height: 30px;
}
.hasListLeft {
  margin-right: 20px;
}
.hasList {
  float: left;
  width: 200px;
  height: 290px;
  color: #333;
  font-size: 12px;
  .title {
    margin-bottom: 5px;
    font-weight: 500;
  }
  .isSelect {
    float: right;
  }
  .bodyS {
    border: 1px solid #d8d8d8;
    overflow: auto;
    width: 100%;
    height: 270px;
    border-radius: 3px;
    padding-top: 30px;
    .list {
      overflow: hidden;
      .list-item {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        .name {
          // width: 140px;
          // width: 100%;
          max-width: 150px;
          float: left;
          padding-right: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .symbol {
          float: right;
          margin-right: 15px;
          cursor: pointer;
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .input-box {
      width: 100%;
      padding: 0 5px;
      box-sizing: border-box;
      img {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 7px;
        top: 7px;
      }
    }
    input {
      width: 100%;
      height: 30px;
      outline: none;
      border: none;
      border-bottom: 1px solid #dcdcdc;
      padding-left: 30px;
      // background: transparent;
    }
  }
}
.inputTitle {
  width: 187px;
  position: absolute;
  left: 2px;
  top: 1px;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
.range {
  margin-top: 10px;
}
.iconPosition {
  position: absolute;
  left: 150px;
  top: 8px;
}
.chosseField {
  width: 164px;
  height: 70px;
  background-color: pink;
  position: absolute;
  left: 10px;
  right: 0px;
}
.intro {
  display: flex;
}
.choseFieldList {
  font-family: SourceHanSansCN-Regular;
  font-size: 14px;
  color: #333333;
  padding-bottom: 20px;
  height: 400px;
  overflow: auto;
}
.choseFieldList li {
  padding: 20px 20px 0px 20px;
}
::v-deep .el-dropdown-menu {
  height: 400px;
  overflow: auto;
}
::v-deep .el-input__inner {
  width: 160px;
  height: 32px;
}
::v-deep .el-input__icon {
  line-height: 20px;
}
::v-deep .el-form-item__label {
  line-height: 32px;
}
.vl {
  display: inline-block;
}
::v-deep .el-form-item__content {
  line-height: 27px;
}
::v-deep .el-select {
  margin-right: 10px;
}
::v-deep .el-form-item__label {
  line-height: 42px;
}
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .remoteBtn {
  position: absolute;
  right: 8px;
  top: 2px;
}
::v-deep .el-button--mini,
.el-button--mini.is-round {
  padding: 7px 11px;
}
::v-deep .el-button--info {
  background: #ffffff;
  border-color: #dcdfe6;
}
::v-deep .el-button--info {
  color: black;
}
::v-deep .el-date-editor {
  display: none !important;
}
::v-deep .el-input {
  width: 160px !important;
}
::v-deep .el-textarea__inner {
  transform: translateY(4px);
  min-height: 32px !important;
}
::v-deep .remoteBtn1 {
  top: 3px;
}
</style>
